<template>
  <div
    style="
      display: flex;
      flex-flow: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    "
  >
    <!--    <div class="py-4 text-h4">Storage Form</div>-->
    <form id="createForm" class="pa-3 pt-4 pb-0 row elevation-0">
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.type"
          label="Type"
          item-text="text"
          item-value="index"
          :items="serverData.additional_service_types"
          clearable
          outlined
          :error-messages="typeErrors"
          @input="$v.formData.type.$touch()"
          @blur="$v.formData.type.$touch()"
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.unit_id"
          label="Unit"
          item-text="text"
          item-value="index"
          :items="serverData.additional_service_units"
          clearable
          outlined
          :error-messages="unit_idErrors"
          @input="$v.formData.unit_id.$touch()"
          @blur="$v.formData.unit_id.$touch()"
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.rate"
          label="Rate"
          clearable
          outlined
          type="Number"
          min="0"
          @change="() => validateMinValue('rate', 0)"
          :error-messages="rateErrors"
          @input="$v.formData.rate.$touch()"
          @blur="$v.formData.rate.$touch()"
        ></v-text-field>
      </div>
      <div
        class="col-sm-6 col-md-6 col-lg-4 col-12"
        v-if="[2, 3].includes(formData.type)"
      >
        <v-select
          v-model="formData.order_status_id"
          :label="formData.type == 2 ? 'Order status' : 'Call attempt status'"
          item-text="text"
          item-value="index"
          :items="
            formData.type == 2
              ? serverData.additional_service_order_statuses
              : serverData.additional_service_call_attempt_order_statuses
          "
          clearable
          outlined
          :error-messages="order_status_idErrors"
          @input="$v.formData.order_status_id.$touch()"
          @blur="$v.formData.order_status_id.$touch()"
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12" v-if="formData.type == 2">
        <v-select
          v-model="formData.order_type_id"
          label="Order type"
          item-text="text"
          item-value="index"
          :items="serverData.order_types"
          clearable
          outlined
        ></v-select>
      </div>
      <v-main class="text-center mt-2 col-12 elevation-0">
        <button
          type="button"
          class="mx-1 btn btn-info px-5 py-3 ls1"
          @click="submitCreateForm"
        >
          Submit
        </button>
        <button
          type="button"
          class="mx-1 btn btn-light px-5 py-3 ls1"
          @click="resetCreateForm"
        >
          Clear
        </button>
        <button
          type="button"
          class="mx-1 btn btn-light px-5 py-3 ls1"
          @click="goBack"
        >
          Back
        </button>
      </v-main>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "AdditionalForm",
  mixins: [validationMixin, fieldValueValidation],
  props: ["setData", "actionFuntion", "serverData", "pageLoader", "initData"],
  validations() {
    return {
      formData: {
        unit_id: { required },
        rate: { required },
        order_status_id: {
          required: requiredIf(() => {
            return [2, 3].includes(this.formData.type);
          }),
        },
        type: { required },
      },
    };
  },
  data: () => ({
    formData: {
      id: null,
      unit_id: null,
      rate: null,
      order_status_id: null,
      type: null,
      order_type_id: null,
    },
  }),
  methods: {
    goBack() {
      this.actionFuntion("back");
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.pageLoader(true);
        // let data = JSON.stringify(this.entCreate);
        const data = Object.fromEntries(
          Object.entries(this.formData).filter(([key, value]) => value !== null)
        );
        this.setData(data);
        this.actionFuntion();
        // this.resetCreateForm();
      }
    },
    resetCreateForm() {
      // console.log("reset create form");
      this.$v.$reset();
      if (this.initData) {
        try {
          let copy = { ...this.serverData.service.values };
          Object.entries(this.formData).forEach((localData) => {
            Object.entries(this.serverData.service.values).forEach(
              (serverData) => {
                if (localData[0] == serverData[0]) {
                  this.formData[localData[0]] = copy[serverData[0]];
                }
              }
            );
          });
        } catch (e) {
          // console.error(e);
          this.formData = {
            id: null,
            rate: null,
            order_status_id: null,
            unit_id: null,
            type: null,
            order_type_id: null,
          };
        }
      } else {
        this.formData = {
          id: null,
          unit_id: null,
          order_status_id: null,
          rate: null,
          order_type_id: null,
          type: null,
        };
      }
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
  },
  computed: {
    unit_idErrors() {
      return this.handleFormValidation("unit_id", this);
    },
    order_status_idErrors() {
      return this.handleFormValidation("order_status_id", this);
    },
    rateErrors() {
      return this.handleFormValidation("rate", this);
    },
    typeErrors() {
      return this.handleFormValidation("type", this);
    },
  },
};
</script>
