<template>
  <div style="display: flex; flex-flow: wrap; flex-direction: column">
    <!--    <div class="py-4 text-h4">Delivery Form</div>-->
    <form id="createForm" class="pa-3 pt-4 pb-0 row elevation-0">
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.type_id"
          label="Type"
          item-text="text"
          item-value="index"
          :items="serverData.return_and_import_delivery_types"
          clearable
          outlined
          :error-messages="type_idErrors"
          @input="$v.formData.type_id.$touch()"
          @blur="$v.formData.type_id.$touch()"
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.unit_id"
          label="Unit"
          item-text="text"
          item-value="index"
          :items="serverData.return_and_import_delivery_units"
          clearable
          outlined
          :error-messages="unit_idErrors"
          @input="$v.formData.unit_id.$touch()"
          @blur="$v.formData.unit_id.$touch()"
        ></v-select>
      </div>
      <div
        class="col-sm-6 col-md-6 col-lg-4 col-12"
        v-if="[1, 5].includes(formData.type_id)"
      >
        <v-select
          v-model="formData.invoicing_order_status"
          label="Invoicing order status"
          item-text="text"
          item-value="index"
          :items="
            serverData.return_and_import_delivery_invoicing_order_statuses
          "
          clearable
          outlined
          :error-messages="invoicing_order_statusErrors"
          @input="$v.formData.invoicing_order_status.$touch()"
          @blur="$v.formData.invoicing_order_status.$touch()"
        ></v-select>
      </div>

      <div class="col-12">
        <rate-datatable
          name="rate"
          :setSku="setRates"
          :skus="formData.rates"
          :hasImport="true"
          :importType="1"
          importLabel="Import rates"
          :exampleFile="serverData?.example_file?.rates_sample_file"
        ></rate-datatable>
      </div>
      <div class="col-12" v-if="[1, 3, 5].includes(formData.type_id)">
        <cost-datatable
          name="additional costs"
          :setSku="setCosts"
          :skus="formData.additional_costs"
          importLabel="Import additional costs"
          :applyOns="serverData.apply_ons"
        ></cost-datatable>
      </div>

      <v-main class="text-center mt-2 col-12 elevation-0">
        <button
          type="button"
          class="mx-1 btn btn-info px-5 py-3 ls1"
          @click="submitCreateForm"
        >
          Submit
        </button>
        <button
          type="button"
          class="mx-1 btn btn-light px-5 py-3 ls1"
          @click="resetCreateForm"
        >
          Clear
        </button>
        <button
          type="button"
          class="mx-1 btn btn-light px-5 py-3 ls1"
          @click="goBack"
        >
          Back
        </button>
      </v-main>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import RateDatatable from "@/own/components/finance/services/addForms/rateContainer/RateDatatable";
import CostDatatable from "@/own/components/finance/services/addForms/costContainer/CostDatatable";
import { required, requiredIf } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import Swal from "sweetalert2";
export default {
  name: "ReturnsAndImportDeliveryForm",
  components: { RateDatatable, CostDatatable },
  mixins: [validationMixin, fieldValueValidation],
  props: ["setData", "actionFuntion", "serverData", "pageLoader", "initData"],
  validations() {
    return {
      formData: {
        type_id: { required },
        unit_id: { required },
        invoicing_order_status: {
          required: requiredIf(() => {
            return [1, 5].includes(this.formData.type_id);
          }),
        },
      },
    };
  },
  data: () => ({
    isLoading: false,
    formData: {
      id: null,
      type_id: null,
      unit_id: null,
      invoicing_order_status: null,

      rates: [],
      additional_costs: [],
    },
  }),
  methods: {
    setCosts(item) {
      // console.log("before update", this.formData.increments, item);
      let copy = { ...this.formData };
      copy.additional_costs = [...item];
      this.formData = copy;
      // console.log("after update", this.formData.increments);
    },
    setRates(item) {
      // console.log("before update", this.formData.rates, item);
      let copy = { ...this.formData };
      copy.rates = [...item];
      this.formData = copy;
      // console.log("after update", this.formData.rates);
    },
    goBack() {
      this.actionFuntion("back");
    },

    submitCreateForm() {
      this.$v.$touch();
      if (this.formData.rates.length === 0) {
        Swal.fire({
          icon: "warning",
          text: "The rate is required!",
        });
        return;
      }
      if (
        [1, 3, 5].includes(this.formData.type_id) &&
        this.formData.additional_costs.length === 0
      ) {
        Swal.fire({
          icon: "warning",
          text: "The additional costs are required!",
        });
        return;
      }
      if (this.$v.$invalid) {
        return;
      } else {
        this.pageLoader(true);
        // let data = JSON.stringify(this.entCreate);
        const data = Object.fromEntries(
          Object.entries(this.formData).filter(([key, value]) => value !== null)
        );
        this.setData(data);
        this.actionFuntion();
        // this.resetCreateForm();
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      if (this.initData) {
        try {
          let copy = { ...this.serverData.service.values };
          Object.entries(this.formData).forEach((localData) => {
            Object.entries(this.serverData.service.values).forEach(
              (serverData) => {
                if (localData[0] == serverData[0]) {
                  this.formData[localData[0]] = copy[serverData[0]];
                }
              }
            );
          });
        } catch (e) {
          // console.error(e);
          this.formData = {
            id: null,
            type_id: null,
            unit_id: null,
            invoicing_order_status: null,

            rates: [],
            additional_costs: [],
          };
        }
      } else {
        this.formData = {
          id: null,
          type_id: null,
          unit_id: null,
          invoicing_order_status: null,

          rates: [],
          additional_costs: [],
        };
      }
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
  },
  computed: {
    type_idErrors() {
      return this.handleFormValidation("type_id", this);
    },
    unit_idErrors() {
      return this.handleFormValidation("unit_id", this);
    },
    invoicing_order_statusErrors() {
      return this.handleFormValidation("invoicing_order_status", this);
    },
  },
};
</script>
