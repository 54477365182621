<template>
  <b-dropdown
    ref="action-dropdown"
    id="action-dropdown"
    text="Actions"
    class="mr-3 mb-1 p-0 col-12"
    toggle-class="poppins btn btn--print-upload"
    menu-class="m-0 p-0 ml-n4"
    no-caret
    variant="upload"
  >
    <template v-slot:button-content>
      <div class="px-2">
        <span class="svg-icon">
          <v-icon size="18">mdi-dns</v-icon>
        </span>
        <span>Actions</span>
        <span class="svg-icon mr-0 ml-2">
          <v-icon size="18">mdi-chevron-down</v-icon>
        </span>
      </div>
    </template>
    <div class="q-status-menu py-1 w-175px">
      <div
        class="drop-item poppins px-3 mx-3 rounded-lg cursor-pointer"
        v-for="(action, index) in types"
        :key="index"
        @click="() => handleClick(action.url)"
      >
        <div
          class="py-2 w-100 text-left drop-text poppins font-size-sm font-weight-medium"
          :class="[
            { 'mt-2': index === 0 },
            { 'mb-2': index === types.length - 1 },
          ]"
        >
          {{ action.name }}
        </div>
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
import swalEdited from "@/core/services/AlertServices/SwalEdited";

export default {
  name: "ActionsButton",
  props: ["items", "types", "refresher", "callback"],
  methods: {
    handleClick(url) {
      if (this.items.length > 0) {
        if (url === "bulk_update") {
          this.callback[url]();
          return;
        } else {
          const isConfirmationRequired = this.permissions.find(
            (permission) => permission.name === url
          ).is_confirmation_required;

          if (isConfirmationRequired) {
            Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
            }).then((result) => {
              if (result.isConfirmed) {
                this.actionRequest(url);
              }
            });
          } else {
            this.actionRequest(url);
          }
        }
      } else {
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one order!",
          icon: "warning",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "OK",
          cancelButtonColor: "#FFA800",
        });
      }
    },
    actionRequest(url) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let selectedItemsIds = this.items.map((item) => item.id);
      // let data = { [`${this.page}`]: selectedItemsIds };
      let data = { asn_ids: selectedItemsIds };
      ApiService.post(`/fulfillment/asn/${url}`, data)
        .then(({ data }) => {
          if (data.hasOwnProperty("error_orders")) {
            let editedItemMessage = `<div><ol>`;
            data.messages.forEach((item) => {
              editedItemMessage += `<li>${item}</li>`;
            });
            editedItemMessage = editedItemMessage + `</ol></div>`;
            Swal.fire({
              title: `Result`,
              html: editedItemMessage,
              icon: data.error_orders === 0 ? "success" : "warning",
              showConfirmButton: true,
            });
          } else swalEdited();
          this.refresher();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  computed: {
    permissions: function () {
      return this.$store.getters.getASNTablePermissions;
    },
  },
};
</script>
