<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :editComponent="componentForEditing"
      :addComponent="componentForAdding"
      :actionsComponent="componentForActions"
      :delete_url="delete_url"
      :urls="urls"
    />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
// import AddIntegration from "@/own/components/datahub/integrations2/AddIntegration";
import EditItem from "@/own/components/datahub/integrations2/EditItem";
import AddItem from "@/own/components/datahub/integrations2/AddItem";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/integrations2.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import DataTableActionSelectorNew from "@/own/components/DataTableActionSelectorNew.vue";
// import introJs from "intro.js";
// import ApiService from "@/core/services/api.service";
// import { SET_GUIDANCE_FINISHED_PAGES } from "@/core/services/store/auth.module";
export default {
  name: "Integrations2",
  components: { DataTable },
  data: () => ({
    componentForEditing: EditItem,
    componentForActions: DataTableActionSelectorNew,
    componentForAdding: AddItem,
    delete_url: `${process.env.VUE_APP_BASE_URL}/datahub/integrationsNew/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getINTEGRATIONS2TableFilters",
      getTableState: "getINTEGRATIONS2TableState",
      getTableData: "getINTEGRATIONS2TableData",
      getTableHeaders: "getINTEGRATIONS2TableHeaders",
      getTableProperties: "getINTEGRATIONS2TableProperties",
      getTableExportUrl: "getINTEGRATIONS2ExportUrl",
      getTablePermissions: "getINTEGRATIONS2TablePermissions",
    },
    urls: {
      delete: `${process.env.VUE_APP_BASE_URL}/datahub/integrationsNew/destroy`,
      status: `${process.env.VUE_APP_BASE_URL}/datahub/integrationsNew/status`,
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
      // .then(() => {
      //   if (
      //     !this.$store.getters.getGuidanceFinishedPages.includes(
      //       "integration"
      //     )
      //   ) {
      //     setTimeout(() => {
      //       introJs()
      //         .setOptions({
      //           steps: [
      //             {
      //               title: "Add Multiple Stores",
      //               element: document.querySelector(".add-btn-guide"),
      //               intro:
      //                 "Connect as many online stores as you like by providing their information right here.",
      //             },
      //           ],
      //           highlightClass: "intro-no-border",
      //           tooltipClass: "tooltip_class",
      //           showBullets: false,
      //           skipLabel: "",
      //           hidePrev: true,
      //         })
      //         .onComplete(() => {
      //           ApiService.post("/api/v2/guide-page/add", {
      //             entity: "integration",
      //           }).finally(() => {
      //             ApiService.post("/api/v2/guide-page/get")
      //               .then(({ data }) => {
      //                 this.$store.commit(
      //                   SET_GUIDANCE_FINISHED_PAGES,
      //                   data.pages
      //                 );
      //               })
      //               .finally(() => {
      //                 if (
      //                   !this.$store.getters.getGuidanceFinishedPages.includes(
      //                     "skus"
      //                   )
      //                 ) {
      //                   introJs()
      //                     .setOptions({
      //                       steps: [
      //                         {
      //                           title: "Manage SKUs Efficiently",
      //                           intro:
      //                             '<p class="font-size-lg">Head over to the SKU page to organize and track your stock-keeping units.</p> <br/> <img src="/img/s3.png" width="270" alt="SKUs">',
      //                           // "Head over to the SKU page to organize and track your stock-keeping units.",
      //                           element: document.querySelector(
      //                             ".w-100.fulfillment-guide-icon > svg"
      //                           ),
      //                         },
      //                       ],
      //                       highlightClass: "intro-no-border",
      //                       tooltipClass: "tooltip_class",
      //                       showBullets: false,
      //                       skipLabel: "",
      //                       doneLabel: "OK",
      //                       hidePrev: true,
      //                       tooltipPosition: "right",
      //                     })
      //                     .start();
      //                 }
      //               });
      //           });
      //         })
      //         // .onExit(() => {
      //         //   ApiService.post("/api/v2/guide-page/add", {
      //         //     entity: "integration",
      //         //   }).finally(() => {
      //         //     ApiService.post("/api/v2/guide-page/get").then(
      //         //       ({ data }) => {
      //         //         this.$store.commit(
      //         //           SET_GUIDANCE_FINISHED_PAGES,
      //         //           data.pages
      //         //         );
      //         //       }
      //         //     );
      //         //   });
      //         // })
      //         .start();
      //     }, 1500);
      //   }
      // });
    },
  },
  computed: {
    isDataLoaded: function() {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
