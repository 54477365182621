<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-0 py-0 my-0"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="ma-0 pa-0 text-capitalize"
            ><h3>{{ name }}</h3></v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            style="text-transform: unset !important"
            color="primary"
            dark
            class="mb-2"
            @click="showAddSkuModal()"
          >
            New {{ name }}
          </v-btn>
          <v-btn
            v-if="hasImport"
            color="primary"
            dark
            class="ml-2 mb-2"
            style="text-transform: unset !important"
            @click="showImportModal()"
          >
            Import
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.from="{ item }">
        {{ area.find((data) => data.index == item.from)?.text }}
      </template>
      <template v-slot:item.to="{ item }">
        {{ area.find((data) => data.index == item.to)?.text }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="showEditSkuModal(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="removeSkus(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <EditRate
      ref="editSku"
      :item="false"
      :actionFunction="editSkus"
      :editType="name == 'rate' ? 'rate' : 'increment'"
      :area="area"
    ></EditRate>
    <ImportData
      ref="importData"
      :type="importType"
      :label="importLabel"
      :setData="setImportDataToDataTable"
      :exampleFile="exampleFile"
    >
    </ImportData>
  </div>
</template>

<script>
import EditRate from "@/own/components/finance/services/addForms/srateContainer/EditRate";
import ImportData from "@/own/components/finance/services/addForms/srateContainer/ImportData";
import Swal from "sweetalert2";
export default {
  name: "RateDatatable",
  props: [
    "setSku",
    "skus",
    "name",
    "hasImport",
    "importType",
    "importLabel",
    "area",
    "exampleFile",
  ],
  components: {
    EditRate,
    ImportData,
  },
  data: () => ({
    dialog: false,
    headers: [
      { text: "From", value: "from" },
      { text: "To", value: "to" },
      { text: "Rate", value: "rate" },
      { text: "Number of tons", value: "number_of_tons" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  computed: {
    items: function () {
      return this.skus;
    },
  },
  methods: {
    showEditSkuModal(item) {
      this.$refs.editSku.$props.item = item;
      this.$refs.editSku.toggleModal();
    },
    showAddSkuModal() {
      this.$refs.editSku.$props.item = false;
      this.$refs.editSku.toggleModal();
    },
    showImportModal() {
      this.$refs.importData.toggleModal();
    },
    setImportDataToDataTable(importedData) {
      this.setSku([]);
      this.setSku(importedData);
    },
    findItemIndex(item, exclude = -1) {
      let index = -1;
      this.skus.forEach((sku, i) => {
        if (item.from == sku.from && item.to == sku.to && i != exclude) {
          index = i;
        }
      });
      // console.log("find index [index,exclude]", index, exclude);
      return index;
    },
    popItemToArray(item) {
      let new_skus = [...this.skus];
      new_skus.push(item);
      this.setSku(new_skus);
      // console.log("add item to array [item]", item, new_skus);
    },
    writeToIndex(index, item) {
      let new_skus = [...this.skus];
      new_skus[index] = { ...item };
      this.setSku(new_skus);
      // console.log(
      //   "write item to index [index,item, items]",
      //   index,
      //   item,
      //   this.skus,
      //   new_skus
      // );
    },
    removeItemFromIndex(index) {
      let new_skus = [...this.skus];
      // console.log(`remove before`, index, new_skus);
      new_skus.splice(index, 1);
      // console.log(`remove after`, index, new_skus);
      this.setSku(new_skus);
    },
    editSkus(item, oldItem = false) {
      if (oldItem) {
        // console.log(
        //   "edit item [item,olditem, items]",
        //   item,
        //   oldItem,
        //   this.skus
        // );
        let oldIndex = this.findItemIndex(oldItem);
        let copyIndex = this.findItemIndex(item, oldIndex);
        if (copyIndex > -1) {
          // console.log(
          //   "edited item got copy on array [copyIndex, oldIndex]",
          //   copyIndex,
          //   oldIndex
          // );
          this.writeToIndex(oldIndex, item);
          this.removeItemFromIndex(copyIndex);
          // if (oldIndex > copyIndex) {
          //   this.removeItemFromIndex(copyIndex);
          //   this.removeItemFromIndex(oldIndex);
          // } else {
          //   this.removeItemFromIndex(oldIndex);
          //   this.removeItemFromIndex(copyIndex);
          // }
          // this.popItemToArray(item);
        } else {
          // console.log(
          //   "writing edited item[oldIndex,item,items]",
          //   oldIndex,
          //   item,
          //   this.skus
          // );
          this.writeToIndex(oldIndex, item);
        }
      } else {
        let copy = this.findItemIndex(item);
        if (copy > -1) {
          this.writeToIndex(copy, item);
        } else {
          this.popItemToArray(item);
        }
      }
      // console.log("final", this.skus);
    },
    removeSkus(item) {
      let index = this.findItemIndex(item);
      // console.log("remove item:", item, index);
      if (index > -1) {
        this.removeItemFromIndex(index);
      } else {
        Swal.fire({
          title: "Error",
          text: `Something went wrong!`,
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
  },
};
</script>
