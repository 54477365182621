<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :showDetailComponent="componentForDetails"
      :printComponent="componentForPrint"
      :uploadDocComponent="componentForUploadDoc"
      :multiUploadComponent="componentForMultiUpload"
      :delete_url="delete_url"
      :urls="urls"
      ref="table"
    >
      <template #actionsButton="{ selectedItems, refresher }">
        <div
          class="mr-md-2 mr-sm-1 mr-0 pa-0"
          :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
        >
          <ActionsButton
            v-if="actionsButtonTypes.length > 0"
            :items="selectedItems"
            :types="actionsButtonTypes"
            :refresher="refresher"
            :class="{ 'col-6': $vuetify.breakpoint.xsOnly }"
          />
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import ActionsButton from "@/own/components/fulfillment/skus/ActionsButton";
import DataTable from "@/own/components/DataTable.vue";
import AddItem from "@/own/components/fulfillment/skus/AddItem.vue";
import EditItem from "@/own/components/fulfillment/skus/EditItem";
import Print from "@/own/components/fulfillment/skus/Print.vue";
import UploadSelect from "@/own/components/UploadSelect.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/skus.module";
import introJs from "intro.js";
import ApiService from "@/core/services/api.service";
import { SET_GUIDANCE_FINISHED_PAGES } from "@/core/services/store/auth.module";
import QueueUploadModal from "@/own/components/Modals/QueueUploadModal.vue";
import ShowDetails from "@/own/components/datatable/ShowDetails.vue";
// import introJs from "intro.js";
// import ApiService from "@/core/services/api.service";
// import { SET_GUIDANCE_FINISHED_PAGES } from "@/core/services/store/auth.module";

export default {
  name: "SKUs",
  components: { DataTable, ActionsButton },
  data: () => ({
    componentForEditing: EditItem,
    componentForAdding: AddItem,
    componentForDetails: ShowDetails,
    componentForPrint: Print,
    componentForUploadDoc: QueueUploadModal,
    componentForMultiUpload: UploadSelect,
    delete_url: `${process.env.VUE_APP_BASE_URL}/fulfillment/skus/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getSKUSTableFilters",
      getTableState: "getSKUSTableState",
      getTableData: "getSKUSTableData",
      getTableHeaders: "getSKUSTableHeaders",
      getTableProperties: "getSKUSTableProperties",
      getTableExportUrl: "getSKUSExportUrl",
      getTablePermissions: "getSKUSTablePermissions",
      getUploadType: "getSKUSUploadType",
    },
    urls: {
      status: `${process.env.VUE_APP_BASE_URL}/fulfillment/skus/status`,
      uploadDoc: `/fulfillment/skus/upload_doc`,
      showUrl: `/fulfillment/skus/show`,
    },
  }),
  methods: {
    permissionChecker(permType) {
      let result = false;
      this.$store.getters[this.store_names.getTablePermissions].forEach(
        (element) => {
          if (element.name === permType) {
            result = true;
          }
        }
      );
      return result;
    },
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
      // .then(() => {
      //   if (
      //     !this.$store.getters.getGuidanceFinishedPages.includes("skus") &&
      //     this.$store.getters.getGuidanceFinishedPages.length >= 1
      //   ) {
      //     // if (this.$store.getters.getGuidanceFinishedPages.length >= 1) {
      //     setTimeout(() => {
      //       introJs()
      //         .setOptions({
      //           steps: [
      //             {
      //               title: "Add a Single SKU",
      //               element: document.querySelector(".add-btn-guide"),
      //               intro:
      //                 "Enter all the details for a single SKU directly within the user interface.",
      //             },
      //             {
      //               title: "Bulk SKU Import",
      //               element: document.querySelector(".import-btn-guide"),
      //               intro:
      //                 "Enter all the details for a single SKU directly within the user interface.",
      //             },
      //             {
      //               title: "Print SKU Barcodes",
      //               element: document.querySelector(".print-btn-guide"),
      //               intro:
      //                 "Generate and print barcodes in various formats from the SKU page.",
      //             },
      //             {
      //               title: "Advanced SKU Filtering",
      //               element: document.querySelector(".filter-btn-guide"),
      //               intro:
      //                 "Apply any combination of filters to quickly locate specific SKUs.",
      //             },
      //             {
      //               title: "Customizable SKU Reports",
      //               element: document.querySelector(".export-btn-guide"),
      //               intro:
      //                 "Export an Excel report tailored to your needs by selecting and organizing data with drag-and-drop functionality.",
      //             },
      //           ],
      //           highlightClass: "intro-no-border",
      //           tooltipClass: "tooltip_class",
      //           showBullets: false,
      //           skipLabel: "",
      //           hidePrev: true,
      //         })
      //         .onComplete(() => {
      //           ApiService.post("/api/v2/guide-page/add", {
      //             entity: "skus",
      //           }).finally(() => {
      //             ApiService.post("/api/v2/guide-page/get")
      //               .then(({ data }) => {
      //                 this.$store.commit(
      //                   SET_GUIDANCE_FINISHED_PAGES,
      //                   data.pages
      //                 );
      //               })
      //               .finally(() => {
      //                 if (
      //                   !this.$store.getters.getGuidanceFinishedPages.includes(
      //                     "asn"
      //                   )
      //                 ) {
      //                   introJs()
      //                     .setOptions({
      //                       steps: [
      //                         {
      //                           title: "ASN Management",
      //                           intro:
      //                             '<p class="font-size-lg">Seamlessly manage all your ASNs directly from the UI.</p> <br/> <img src="/img/s2.png" width="270" alt="ASN">',
      //                           // "Seamlessly manage all your ASNs directly from the UI.",
      //                           element: document.querySelector(
      //                             ".w-100.warehouse_management-guide-icon > svg"
      //                           ),
      //                         },
      //                       ],
      //                       highlightClass: "intro-no-border",
      //                       tooltipClass: "tooltip_class",
      //                       showBullets: false,
      //                       skipLabel: "",
      //                       doneLabel: "OK",
      //                       hidePrev: true,
      //                       tooltipPosition: "right",
      //                     })
      //                     .start();
      //                 }
      //               });
      //           });
      //         })
      //         .start();
      //     }, 1500);
      //   }
      // });

      // .onbeforechange(function(targetElement) {
      //   // if(targetElement.classList.contains("filter-btn-guide")){
      //   if (targetElement.classList.contains("print-btn-guide")) {
      //     this.$refs.table.handleFilterSidebarClick();
      //   }
      // })
      // .onAfterChange(function(targetElement) {
      //   if (targetElement.classList.contains("print-btn-guide")) {
      //     this.$refs?.table?.handleFilterSidebarClick();
      //   }
      // })
      // .onExit(() => {
      //   ApiService.post("/api/v2/guide-page/add", {
      //     entity: "skus",
      //   });
      // })
    },
  },
  computed: {
    actionsButtonTypes: function() {
      let types = [];

      const typeNames = ["Approve", "Disable", "Delete"];

      const typesUrl = ["bulk_enable", "bulk_disable", "bulk_destroy"];
      const typesPermission = ["status", "status", "destroy"];

      typesUrl.forEach((item, index) => {
        if (this.permissionChecker(typesPermission[index]))
          types.push({
            name: typeNames[index],
            url: `skus/${item}`,
          });
      });

      return types;
    },
    isDataLoaded: function() {
      return this.$store.getters[this.store_names.getTableData];
    },
    types: function() {
      return [
        { name: "2d barcode", value: "barcode2d" },
        { name: "QR barcode", value: "barcodeQr" },
      ];
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
