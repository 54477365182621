<template>
  <div style="display: flex; flex-flow: wrap; flex-direction: column">
    <!--    <div class="py-4 text-h4">Storage Form</div>-->
    <form id="createForm" class="pa-3 pt-4 pb-0 row elevation-0">
      <div class="col-12 col-sm-6">
        <v-select
          v-model="formData.truck_id"
          label="Truck"
          item-text="text"
          item-value="index"
          :items="serverData.trucks"
          clearable
          outlined
          :error-messages="truck_idErrors"
          @input="$v.formData.truck_id.$touch()"
          @blur="$v.formData.truck_id.$touch()"
        ></v-select>
      </div>
      <div
        class="col-sm-6 col-md-6 col-lg-6 col-12"
        v-if="formData.truck_id == 1"
      >
        <v-text-field
          v-model="formData.fixed_rate"
          label="Fixed rate"
          clearable
          outlined
          type="Number"
          min="0"
          @change="() => validateMinValue('fixed_rate', 0)"
          :error-messages="fixed_rateErrors"
          @input="$v.formData.fixed_rate.$touch()"
          @blur="$v.formData.fixed_rate.$touch()"
        ></v-text-field>
      </div>
      <!-- <div class="col-sm-6 col-md-6 col-12" v-if="formData.truck_id == 2">
        <v-select
          v-model="formData.truck_type_id"
          label="Truck type"
          item-text="text"
          item-value="index"
          :items="serverData.truck_types"
          clearable
          outlined
          :error-messages="truck_type_idErrors"
          @input="$v.formData.truck_type_id.$touch()"
          @blur="$v.formData.truck_type_id.$touch()"
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-12" v-if="formData.truck_id == 2">
        <v-select
          v-model="formData.pickup_location_id"
          label="Pickup location"
          item-text="text"
          item-value="index"
          :items="serverData.regions"
          clearable
          outlined
          :error-messages="pickup_location_idErrors"
          @input="$v.formData.pickup_location_id.$touch()"
          @blur="$v.formData.pickup_location_id.$touch()"
        ></v-select>
      </div> -->
      <div class="col-sm-6 col-md-6 col-12" v-if="formData.truck_id == 3">
        <v-select
          v-model="formData.carrier_id"
          label="Carrier"
          item-text="text"
          item-value="index"
          :items="serverData.carriers"
          clearable
          outlined
          :error-messages="carrier_idErrors"
          @input="$v.formData.carrier_id.$touch()"
          @blur="$v.formData.carrier_id.$touch()"
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-12" v-if="formData.truck_id == 3">
        <v-select
          v-model="formData.unit_id"
          label="Unit"
          item-text="text"
          item-value="index"
          :items="serverData.stock_collection_units"
          clearable
          outlined
          :error-messages="unit_idErrors"
          @input="$v.formData.unit_id.$touch()"
          @blur="$v.formData.unit_id.$touch()"
        ></v-select>
      </div>
      <div class="col-12 px-0 mx-0" v-if="formData.truck_id == 2">
        <rate-datatable
          name="rate"
          :setSku="setRates"
          :skus="formData.rates"
          :hasImport="true"
          :importType="1"
          importLabel="Import rates"
          :area="serverData.stock_collection_areas_of_uae"
          :exampleFile="serverData?.example_file?.rates_sample_file"
        ></rate-datatable>
      </div>

      <v-main class="text-center mt-2 col-12 elevation-0">
        <button
          type="button"
          class="mx-1 btn btn-info px-5 py-3 ls1"
          @click="submitCreateForm"
        >
          Submit
        </button>
        <button
          type="button"
          class="mx-1 btn btn-light px-5 py-3 ls1"
          @click="resetCreateForm"
        >
          Clear
        </button>
        <button
          type="button"
          class="mx-1 btn btn-light px-5 py-3 ls1"
          @click="goBack"
        >
          Back
        </button>
      </v-main>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import RateDatatable from "@/own/components/finance/services/addForms/srateContainer/RateDatatable";
import Swal from "sweetalert2";
export default {
  name: "StockCollectionForm.vue",
  components: { RateDatatable },
  mixins: [validationMixin, fieldValueValidation],
  props: ["setData", "actionFuntion", "serverData", "pageLoader", "initData"],
  validations() {
    return {
      formData: {
        truck_id: { required },
        fixed_rate: {
          required: requiredIf(() => {
            return this.formData.truck_id == 1;
          }),
        },
        // truck_type_id: {
        //   required: requiredIf(() => {
        //     return this.formData.truck_id == 2;
        //   }),
        // },
        // pickup_location_id: {
        //   required: requiredIf(() => {
        //     return this.formData.truck_id == 2;
        //   }),
        // },
        carrier_id: {
          required: requiredIf(() => {
            return this.formData.truck_id == 3;
          }),
        },
        unit_id: {
          required: requiredIf(() => {
            return this.formData.truck_id == 3;
          }),
        },
        rates: {
          required: requiredIf(() => {
            return this.formData.truck_id == 3;
          }),
        },
      },
    };
  },
  data: () => ({
    formData: {
      id: null,
      truck_id: null,
      fixed_rate: null,
      // truck_type_id: null,
      // pickup_location_id: null,
      carrier_id: null,
      unit_id: null,
      rates: [],
    },
  }),
  methods: {
    setRates(item) {
      // console.log("before update", this.formData.rates, item);
      let copy = { ...this.formData };
      copy.rates = [...item];
      this.formData = copy;
      // console.log("after update", this.formData.rates);
    },
    goBack() {
      this.actionFuntion("back");
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.formData.truck_id === 3 && this.formData.rates.length === 0) {
        Swal.fire({
          icon: "warning",
          text: "The rate is required!",
        });
        return;
      }
      if (this.$v.$invalid) {
        return;
      } else {
        this.pageLoader(true);
        // let data = JSON.stringify(this.entCreate);

        const data = Object.fromEntries(
          Object.entries(this.formData).filter(([key, value]) => value !== null)
        );
        this.setData(data);
        this.actionFuntion();
        // this.resetCreateForm();
      }
    },
    resetCreateForm() {
      // console.log("reset create form");
      this.$v.$reset();
      if (this.initData) {
        try {
          let copy = { ...this.serverData.service.values };
          Object.entries(this.formData).forEach((localData) => {
            Object.entries(this.serverData.service.values).forEach(
              (serverData) => {
                if (localData[0] == serverData[0]) {
                  this.formData[localData[0]] = copy[serverData[0]];
                }
              }
            );
          });
        } catch (e) {
          // console.error(e);
          this.formData = {
            id: null,
            truck_id: null,
            fixed_rate: null,
            // truck_type_id: null,
            // pickup_location_id: null,
            carrier_id: null,
            unit_id: null,
            rates: [],
          };
        }
      } else {
        this.formData = {
          id: null,
          truck_id: null,
          fixed_rate: null,
          // truck_type_id: null,
          // pickup_location_id: null,
          carrier_id: null,
          unit_id: null,
          rates: [],
        };
      }
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
  },
  computed: {
    truck_idErrors() {
      return this.handleFormValidation("truck_id", this);
    },
    fixed_rateErrors() {
      return this.handleFormValidation("fixed_rate", this);
    },
    // truck_type_idErrors() {
    //   return this.handleFormValidation("truck_type_id", this);
    // },
    // pickup_location_idErrors() {
    //   return this.handleFormValidation("pickup_location_id", this);
    // },
    carrier_idErrors() {
      return this.handleFormValidation("carrier_id", this);
    },
    unit_idErrors() {
      return this.handleFormValidation("unit_id", this);
    },
  },
};
</script>
