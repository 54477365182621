<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Create Pickup</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--begin::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 mx-3 row elevation-0">
          <div class="col-12 h-75px">
            <v-combobox
              v-model="formData.order_ids"
              label="Orders"
              outlined
              dense
              @change="handleChangeMultiple"
              multiple
              deletable-chips
              clearable
              allow-overflow
              small-chips
            />
          </div>
          <div class="col-sm-6 col-md-4 col-12">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  label="Collection date"
                  readonly
                  clearable
                  dense
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :error-messages="collection_dateErrors"
                  @input="$v.formData.collection_date.$touch()"
                  @blur="$v.formData.collection_date.$touch()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="formData.collection_date"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </div>

          <div class="col-sm-6 col-md-4 col-12 h-75px">
            <v-select
              v-model="formData.package_type"
              label="Package type"
              item-text="text"
              item-value="index"
              :items="serverData?.package_types"
              clearable
              outlined
              dense
              :error-messages="packageTypeErrors"
              @input="$v.formData.package_type.$touch()"
              @blur="$v.formData.package_type.$touch()"
            ></v-select>
          </div>

          <div class="col-sm-6 col-md-4 col-12 h-75px">
            <v-select
              v-model="formData.collection_type"
              label="Collection type"
              item-text="text"
              item-value="index"
              :items="serverData?.collection_types"
              clearable
              outlined
              dense
              :error-messages="packageTypeErrors"
              @input="$v.formData.collection_type.$touch()"
              @blur="$v.formData.collection_type.$touch()"
            ></v-select>
          </div>

          <div class="col-12 col-sm-6 col-md-4 h-75px">
            <v-select
              v-model="formData.vehicle_type_id"
              label="Vehicle type"
              :items="serverData?.vehicle_types"
              item-text="text"
              item-value="index"
              clearable
              outlined
              dense
            ></v-select>
          </div>

          <div class="col-12 col-sm-6 col-md-4 h-75px">
            <v-text-field
              v-model="formData.number_of_packages"
              :label="number_of_packages_label"
              type="number"
              min="0"
              clearable
              outlined
              dense
              @change="() => validateMinValue('number_of_packages', 0)"
            ></v-text-field>
          </div>
          <div
            class="col-12 col-sm-6 col-md-4 h-75px"
            v-if="formData.package_type == '1'"
          >
            <v-text-field
              v-model="formData.total_number_of_boxes"
              label="Total number of boxes"
              type="number"
              min="0"
              clearable
              outlined
              dense
              @change="() => validateMinValue('total_number_of_boxes', 0)"
            ></v-text-field>
          </div>

          <div
            class="col-12 col-sm-6 col-md-4"
            :class="{ 'col-md-4': formData.package_type === 1 }"
          >
            <v-text-field
              class
              v-model="formData.weight"
              type="number"
              min="0"
              label="Weight"
              clearable
              outlined
              dense
              @change="() => validateMinValue('weight', 0)"
            ></v-text-field>
          </div>
          <div
            class="col-12 col-sm-6 col-md-4"
            :class="{ 'col-md-4': formData.package_type === 1 }"
          >
            <v-text-field
              class
              v-model="formData.volumetric_weight"
              type="number"
              min="0"
              label="Volumetric weight"
              clearable
              outlined
              dense
              @change="() => validateMinValue('volumetric_weight', 0)"
            ></v-text-field>
          </div>
        </form>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="my-2 d-flex flex-grow-1 align-end justify-center">
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submit"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { getToken } from "@/core/services/jwt.service";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "CreatePickup",
  mixins: [validationMixin, fieldValueValidation],
  props: ["selected", "updateTableContent", "pageLoader"],
  validations() {
    return {
      formData: {
        collection_date: { required },
        package_type: { required },
        collection_type: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    serverData: null,
    menu1: false,
    formData: {
      order_ids: [],
      vehicle_type_id: null,
      collection_date: null,
      package_type: null,
      number_of_packages: null,
      total_number_of_boxes: null,
      weight: null,
      volumetric_weight: null,
      collection_type: null,
    },
  }),
  methods: {
    async toggleModal() {
      this.reset();
      if (!this.dialog) {
        await this.loadDataFromServer();
        this.setOrders();
      }
      this.dialog = !this.dialog;
    },
    handleChangeMultiple(val) {
      let seperated = [];
      val.forEach((element) => {
        let subElement = element.trim().split(/(?:,| )+/);
        subElement.forEach((sub) => {
          seperated.push(sub);
        });
      });
      this.formData.order_ids = seperated;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    createNewItem() {
      this.$nextTick(() => {
        this.$refs.collectionAddressComponent.toggleModal();
      });
    },
    async getData(value) {
      let data = { q: value };
      // if (this.userId) data.client = this.userId;
      if (this.customer_id) data.client = this.customer_id;

      await fetch(`${process.env.VUE_APP_BASE_URL}/orders/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          this.entries = res.orders;
        })
        .finally(() => (this.isLoading = false));
    },
    setOrders() {
      this.formData.order_ids = this.selected.map((item) => item.systemId);
    },
    setDataOfAddress(data, type) {
      this.formData[`${type}`] = data;
    },
    dateFormatter(date) {
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
    reset() {
      this.$v.$reset();
      this.formData = {
        order_ids: [],
        vehicle_type_id: null,
        collection_date: null,
        package_type: null,
        number_of_packages: null,
        total_number_of_boxes: null,
        weight: null,
        volumetric_weight: null,
        collection_type: null,
      };
    },
    async loadDataFromServer() {
      this.pageLoader(true);
      await ApiService.post("/shipping/pickup_orders/create")
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (!this.formData.order_ids.length) {
        Swal.fire({
          title: "Info",
          text: `Orders are required!`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      this.pageLoader(true);

      const data = {
        ...this.formData,
        orders: [...this.formData.order_ids],
      };
      const [year, month, day] = this.formData.collection_date.split("-");
      data.collection_date = `${day}-${month}-${year}`;

      ApiService.post(`fulfillment/orders/order_pickup_create`, data)
        .then((res) => {
          Swal.fire({
            title: "Success",
            html: `Updated successfully`,
            icon: "success",
            showConfirmButton: true,
            // timer: 2500,
          });
          this.toggleModal();
          this.updateTableContent();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    dateMaker(data) {
      let date = new Date(data);
      return date;
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    onChangeOrders() {
      //validate
      this.$v.formData.order_ids.$touch();
      //set box number
      if (this.formData.package_type === 2) {
        this.formData.number_of_packages = this.formData.order_ids.length;
      }
    },
  },
  computed: {
    warehouses: function () {
      return this.$store.getters.getORDERSWarehouses;
    },
    number_of_packages_label() {
      return this.formData.package_type == "2"
        ? "Number of boxes"
        : "Number of pallet";
    },
    collection_dateErrors() {
      return this.handleFormValidation("collection_date", this);
    },
    computedDateFormatted() {
      return this.formatDate(this.formData.collection_date);
    },
    packageTypeErrors() {
      return this.handleFormValidation("package_type", this);
    },
    collectionTypeErrors() {
      return this.handleFormValidation("collection_type", this);
    },
  },
  watch: {
    search(val) {
      clearTimeout(this.typingTimer);
      this.isLoading = true;
      if (val) {
        this.typingTimer = setTimeout(() => {
          this.getData(val.trim());
        }, this.doneTypingInterval);
      } else {
        this.isLoading = false;
        return;
      }
    },
  },
};
</script>
