<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template> -->
      <div class="bg-white poppins">
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">
            {{ editType === "rate" ? "Rate" : "Increment" }}
          </h4>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <form class="pt-4">
            <v-select
              v-model="formData.from"
              label="From"
              item-text="text"
              item-value="index"
              clearable
              outlined
              :items="area"
              :error-messages="fromErrors"
              @input="$v.formData.from.$touch()"
              @blur="$v.formData.from.$touch()"
            ></v-select>
            <v-select
              v-model="formData.to"
              label="To"
              item-text="text"
              item-value="index"
              clearable
              outlined
              :items="area"
              :error-messages="toErrors"
              @input="$v.formData.to.$touch()"
              @blur="$v.formData.to.$touch()"
            ></v-select>
            <v-text-field
              v-model="formData.rate"
              label="Rate"
              clearable
              outlined
              type="Number"
              @change="() => validateMinValue('rate', 0)"
              :error-messages="rateErrors"
              @input="$v.formData.rate.$touch()"
              @blur="$v.formData.rate.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="formData.number_of_tons"
              label="Number of tons"
              clearable
              outlined
              type="Number"
              @change="() => validateMinValue('number_of_tons', 0)"
              :error-messages="number_of_tonsErrors"
              @input="$v.formData.number_of_tons.$touch()"
              @blur="$v.formData.number_of_tons.$touch()"
            ></v-text-field>
            <!-- new city field in delivery type service if country is selected , not required -->
            <v-main class="text-center mt-4">
              <v-btn
                style="text-transform: unset !important"
                class="mr-4"
                color="primary"
                @click="submitEditForm"
              >
                Submit
              </v-btn>
              <v-btn
                style="text-transform: unset !important"
                @click="resetFormData"
              >
                Clear
              </v-btn>
            </v-main>
          </form>
        </v-card-text>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
// import Swal from "sweetalert2";
export default {
  name: "EditRate",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      from: { required },
      to: {
        required,
      },
      rate: { required },
      number_of_tons: { required },
    },
  },
  // props: ["item", "actionFunction"],
  props: {
    item: {},
    actionFunction: {
      type: Function,
    },
    editType: {
      type: String,
      default: "rate",
    },
    area: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    dialog: false,
    isLoading: false,
    search: null,
    count: null,
    entries: [],
    formData: {
      from: null,
      to: null,
      rate: null,
      number_of_tons: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        // this.formData.system_code = this.items.map(sku =>
        //   sku.skuId == this.formData.sku_id ? sku.systemCode : null
        // )[0];
        // console.log("supermode is ", this.formData, this.items);
        this.actionFunction(
          { ...this.formData },
          this.item ? this.item : false
        );
        this.toggleModal();
      }
    },
    resetFormData() {
      this.$v.$reset();
      if (this.item) {
        let copy = JSON.parse(JSON.stringify(this.item));
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.item).forEach((serverData) => {
            if (localData[0] == serverData[0]) {
              this.formData[localData[0]] = copy[serverData[0]];
            }
          });
        });
      } else {
        this.formData = {
          from: null,
          to: null,
          rate: null,
          number_of_tons: null,
        };
      }
    },
    toggleModal() {
      this.resetFormData();
      this.dialog = !this.dialog;
    },
  },
  computed: {
    fromErrors: function () {
      return this.handleFormValidation("from", this);
    },
    toErrors: function () {
      return this.handleFormValidation("to", this);
    },
    rateErrors: function () {
      return this.handleFormValidation("rate", this);
    },
    number_of_tonsErrors: function () {
      return this.handleFormValidation("number_of_tons", this);
    },
  },
};
</script>
