<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    max-width="1200px"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Edit service</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              Select type of service
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2">
              Service {{ forms[formData.service_type_id]?.title }}
              form
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card elevation="0" class="mb-4">
                <form id="createForm" class="pa-3 pt-4 pb-0 row">
                  <div class="col-sm-6 col-md-6 col-lg-6 col-12">
                    <v-text-field
                      v-model="formData.name"
                      label="Name"
                      clearable
                      dense
                      outlined
                      :error-messages="nameErrors"
                      @input="$v.formData.name.$touch()"
                      @blur="$v.formData.name.$touch()"
                    ></v-text-field>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-12">
                    <v-select
                      v-model="formData.service_type_id"
                      label="Service type"
                      item-text="text"
                      item-value="index"
                      :items="serverData.service_types"
                      clearable
                      dense
                      outlined
                      :error-messages="service_type_idErrors"
                      @input="$v.formData.service_type_id.$touch()"
                      @blur="$v.formData.service_type_id.$touch()"
                    ></v-select>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 col-12">
                    <v-text-field
                      v-model="formData.description"
                      label="Description"
                      clearable
                      dense
                      outlined
                    ></v-text-field>
                  </div>
                  <v-main class="text-center mt-4">
                    <button
                      type="button"
                      class="btn btn-info px-5 py-3 ls1"
                      @click.prevent="handleStage1"
                    >
                      Continue
                    </button>
                  </v-main>
                </form>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card
                class="mb-4 ma-0 pa-0"
                elevation="0"
                v-if="step2FormComponent"
              >
                <component
                  ref="step2Form"
                  v-bind:is="step2FormComponent"
                  :setData="setForm2Data"
                  :actionFuntion="handleStage2"
                  :serverData="serverData"
                  :pageLoader="pageLoader"
                  :initData="true"
                ></component>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <!--end::Body-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import DeliveryForm from "@/own/components/finance/services/addForms/DeliveryForm";
import FulfillmentForm from "@/own/components/finance/services/addForms/FulfillmentForm";
import StorageForm from "@/own/components/finance/services/addForms/StorageForm";
// import FulfillmentDelivery from "@/own/components/finance/services/addForms/FulfillmentDeliveryForm.vue";
import AdditionalForm from "@/own/components/finance/services/addForms/AdditionalForm.vue";
import StockCollectionForm from "@/own/components/finance/services/addForms/StockCollectionForm.vue";
import ReturnsAndImportDeliveryForm from "@/own/components/finance/services/addForms/ReturnsAndImportDeliveryForm.vue";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
      service_type_id: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    step: 1,
    step2Form: null,
    formData: {
      id: null,
      name: null,
      description: null,
      service_type_id: null,
      values: {},
    },
    forms: {
      2: { form: StorageForm, title: "storage" },
      3: { form: DeliveryForm, title: "delivery" },
      1: { form: FulfillmentForm, title: "fulfillment" },
      7: { form: AdditionalForm, title: "additional" },
      8: { form: StockCollectionForm, title: "stock collection" },
      9: {
        form: ReturnsAndImportDeliveryForm,
        title: "returns and import delivery",
      },
    },
  }),
  methods: {
    handleStage1() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.step2Form = this.forms[this.formData.service_type_id].form;
      this.$nextTick(function () {
        if (this.step2Form) {
          try {
            this.$refs.step2Form.resetCreateForm();
          } catch (e) {
            // console.log(e, this.$refs);
          }
        }
      });
      this.step = 2;
    },
    handleStage2(action = "submit") {
      if (action === "submit") {
        this.submitEditForm();
      } else {
        this.step = 1;
      }
    },
    setForm2Data(item) {
      // console.log("before ap", this.formData.clients);
      let copy = { ...this.formData };
      copy.values = item;
      this.formData = copy;
      // console.log("after ap", this.formData.clients);
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/cod/finance_services/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm();
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal() {
      this.step = 1;
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.serverData = null;
      } else {
        this.loadDataFromServer();

        this.dialog = !this.dialog;
      }
    },
    validateData() {
      return true;
    },
    convertToFormData() {
      return this.formData;
    },
    submitEditForm() {
      this.pageLoader(true);
      let data = this.convertToFormData();
      ApiService.post("/cod/finance_services/update", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Record has been successfully updated!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.resetEditForm();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      this.$v.$reset();
      if (this.serverData) {
        let copy = { ...this.serverData.service };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.serverData.service).forEach((serverData) => {
            if (localData[0] == serverData[0]) {
              this.formData[localData[0]] = copy[serverData[0]];
              // console.log(serverData[0], copy[serverData[0]]);
            }
          });
        });
      } else {
        this.formData = {
          id: null,
          name: null,
          description: null,
          service_type_id: null,
          values: {},
        };
      }
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    step2FormComponent: function () {
      return this.step2Form;
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    service_type_idErrors() {
      return this.handleFormValidation("service_type_id", this);
    },
  },
};
</script>
